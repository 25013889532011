
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Comments',
  computed: {
    ...mapState(currentStore, {
      comments: (store) => store.project?.data.reportNotes ?? '',
      project: 'project',
    }),
  },
})
