import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07c2e0a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Comments CustomScrollbar" }
const _hoisted_2 = { class: "Heading1" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.project?.getCommentsOnProjectTitle()), 1),
    _createElementVNode("div", {
      class: "RichText",
      innerHTML: _ctx.comments
    }, null, 8, _hoisted_3)
  ]))
}